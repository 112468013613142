
import { ProgramFormula, StateType } from "@/types";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";

import { programTypes } from '@/variables'
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component
export default class FilterPrograms extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) filter!: any;

  coaches = [] as any[];

  _programTypes = programTypes;

  programFormulas = [
    {
      text: 'Algemene opzeg',
      value: ProgramFormula.ALGEMEEN_OPZEG
    },
    {
      text: 'Algemene verbreking',
      value: ProgramFormula.ALGEMEEN_VERBREKING
    },
    {
      text: 'Bijzonder',
      value: ProgramFormula.BIJZONDER
    },
    {
      text: 'Collectief',
      value: ProgramFormula.COLLECTIEF
    },
    {
      text: 'Medische overmacht',
      value: ProgramFormula.MEDISCH_OVERMACHT
    },
    {
      text: 'Vrijwillig',
      value: ProgramFormula.VRIJWILLIG
    }
  ]

  showOptions = [
    {
      text: 'Enkel Actieve',
      value: 'ONLY_ACTIVE',
    },
    {
      text: 'Alle programmas',
      value: 'ALL',
    },
    {
      text: 'Enkel gearchiveerde',
      value: 'ONLY_ARCHIVED'
    },
  ]

  async created() {

    const coaches = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'ADMIN'])))

    this.coaches = coaches.docs.map(e => {
      return {
        ...e.data(),
        id: e.id,
        fullName: e.data().firstname + ' ' + e.data().lastname
      }
    })
  }


  @Watch("filter")
  onChange() {
    Object.assign(this.filterCopy, this.filter);
  }

  filterCopy = {
    formula: [] as number[],
    type: [] as number[],
    coach: [] as string[],
    status: [] as StateType[],
    show: 'ONLY_ACTIVE',
  };

  submit() {
    this.$emit("filter", this.filterCopy);
    this.show = false;
  }

  clearFilter() {
    this.filterCopy = {
      formula: [],
      type: [],
      coach: [],
      status: [],
      show: 'ONLY_ACTIVE',
    };
    this.submit();
  }
}
