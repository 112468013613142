import { ActivityType, ProgramType } from "./types";

export const programTypes = [
  {
    text: 'Groepsbegeleiding',
    value: ProgramType.GROUP
  },
  {
    text: 'Individuele begeleiding',
    value: ProgramType.INDIVIDUAL
  },
  {
    text: 'Gemengde begeleiding',
    value: ProgramType.MIXED
  }
]


export const activityTypes = [
  {
    text: 'Individuele begeleiding',
    value: ActivityType.INDIVIDUAL_COACHING,
  },
  {
    text: 'Assesment',
    value: ActivityType.ASSESMENT
  },
  {
    text: 'Begeleiding',
    value: ActivityType.CLASSIC_TRAINING
  },
  {
    text: 'Webinar',
    type: ActivityType.WEBINAR,
  },
  {
    text: 'Workshop',
    type: ActivityType.WORKSHOP
  }
]