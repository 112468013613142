
import { Component, Vue, Watch } from "vue-property-decorator";

import { ProgramFormula, Program, ProgramType, StateType, GenericState, Phase } from "@/types";
import { ROOT_ACTIONS } from "@/store/actions";
import FilterPrograms from "../../../components/dialogs/FilterPrograms.vue";
import { User } from "@/store/user/types";
import { QueryFieldFilterConstraint, collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";


@Component({
  components: {
    AdminPresenceProgramCard: () =>
      import("@/components/cards/AdminPresenceProgramCard.vue"),
    FilterPrograms,
    ExportData: () => import('@/components/dialogs/ExportData.vue')
  },
})
export default class Overview extends Vue {
  programs: Program[] = []

  users: Record<string, User> = {};

  searchInput = '';

  loading = {
    programs: false,
  }

  dialogs = {
    filter: false,
    fab: false,
    exportData: false,
  }

  filter = {
    formula: [] as ProgramFormula[],
    type: [] as any[],
    coach: [] as string[],
    status: [] as StateType[],
    show: 'ONLY_ACTIVE',
  }

  formulaTypes = [
    {
      text: 'Groepsbegeleiding',
      value: ProgramType.GROUP
    },
    {
      text: 'Individuele begeleiding',
      value: ProgramType.INDIVIDUAL
    },
    {
      text: 'Gemengde begeleiding',
      value: ProgramType.MIXED
    }
  ]

  setFilter(filter: any) {
    this.filter = filter;
    localStorage.setItem('programsFilter', JSON.stringify(this.filter));
  }

  get isFiltering(): boolean {
    return !(this.filter.formula.length === 0 && this.filter.type.length === 0 && this.filter.coach.length === 0 && this.filter.status.length === 0 && this.filter.show === 'ONLY_ACTIVE')
  }

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Presence', target: '/' }, { text: 'Programmas', target: '/admin/presence/overview' }])
    this.loading.programs = true
    await this.fetchUsers()
    await this.fetchPrograms()


    this.loading.programs = false;

    const savedFilter = localStorage.getItem('programsFilter')
    if (savedFilter) {
      this.filter = JSON.parse(savedFilter);
    }
  }

  async fetchUsers() {
    const fetchedUsers = await getDocs(collection(firestore, 'users'));

    const users = fetchedUsers.docs.map(e => {
      return {
        ...e.data() as User,
        id: e.id,
      }
    })

    users.forEach(u => {
      Object.assign(this.users, {
        [u.id]: u
      })
    })
  }

  @Watch('filter', { deep: true })
  async fetchPrograms() {
    const constraints: QueryFieldFilterConstraint[] = []

    if (this.filter.coach.length > 0) constraints.push(where('coach', 'in', this.filter.coach))

    if (this.filter.formula.length > 0) constraints.push(where('formula', 'in', this.filter.formula))

    if (this.filter.type.length > 0) constraints.push(where('type', 'in', this.filter.type))

    if (this.filter.show === 'ONLY_ACTIVE') constraints.push(where('isArchived', '==', false))

    if (this.filter.show === 'ONLY_ARCHIVED') constraints.push(where('isArchived', '==', true))
    

    let programDocs = await getDocs(query(collection(firestore, 'programs'), ...constraints));

    let programs = [] as Program[];

    programDocs.forEach(program => {
      programs.push({
        ...program.data() as Program,
        id: program.id,
      })
    })

    // sort programs by title
    this.programs = programs = programs.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    })
  }

  get filteredPrograms() {
    if (this.searchInput.length === 0) return this.programs;
    return this.programs.filter(e => e.title.toLowerCase().includes(this.searchInput.toLowerCase()));
  }



  async exportData() {
    const data = [
      ['titel', 'formule', 'coach', 'deelnemer', 'start', 'einde']
    ];

    const fetchedUsers = await getDocs(collection(firestore, 'users'));
    const users = fetchedUsers.docs.map(e => {
      return {
        ...e.data() as User,
        id: e.id,
      }
    })

    this.programs.forEach(program => {
      const participant = users.find(e => program.participant = e.id);
      const coach = users.find(e => e.id === program.coach)
      data.push([program.title, program.formula.toString(), `${coach?.firstname} ${coach?.lastname}`, `${participant?.firstname} ${participant?.lastname}`, program.start, program.end])
    })

    let csvContent = "data:text/csv;charset=utf-8,"
      + data.map(e => e.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

}
